
        :root {
            --primary-green: #4CAF50;
            --secondary-orange: #FF9800;
            --text-color: #333333;
        }

        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        body {
            font-family: 'Arial', sans-serif;
            background-color: #ffffff;
            color: var(--text-color);
        }

        .video-container {
            height: 25vh;
            overflow: hidden;
            position: relative;
            background-color: #ffffff;
        }

        .video-container video {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        main {
            max-width: 1200px;
            margin: 0 auto;
            padding: 2rem;
        }

        .tagline {
            text-align: center;
            font-size: 1.5rem;
            color: var(--primary-green);
            margin-bottom: 0.1rem;
            font-weight: bold;
        }

        .tagline_2 {
            text-align: center;
            font-size: 1rem;
            margin-bottom: 1rem;
        }

        .search-container {
            max-width: 600px;
            margin: 1rem auto 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .search-bar-container {
            display: flex;
            width: 100%;
            margin-bottom: 1rem;
        }

        .search-bar {
            flex-grow: 1;
            padding: 1rem;
            font-size: 1rem;
            border: 2px solid var(--primary-green);
            border-right: none;
            border-radius: 30px 0 0 30px;
            box-shadow: 0 2px 10px rgba(76, 175, 80, 0.1);
            transition: all 0.3s ease;
        }

        .search-button {
            padding: 1rem 1.5rem;
            font-size: 1rem;
            background-color: var(--primary-green);
            color: white;
            border: none;
            border-radius: 0 30px 30px 0;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .category-dropdown {
            width: 100%;
            max-width: 600px;
            margin: 0 auto 1rem;
            position: relative;
        }

        .category-select {
            width: 100%;
            padding: 1rem;
            font-size: 1rem;
            border: 2px solid var(--primary-green);
            border-radius: 30px;
            background-color: white;
            color: var(--text-color);
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            cursor: pointer;
            transition: all 0.3s ease;
        }

        .category-select:focus {
            outline: none;
            box-shadow: 0 2px 15px rgba(76, 175, 80, 0.2);
        }

        .category-dropdown::after {
            content: '\25BC';
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            color: var(--primary-green);
            pointer-events: none;
        }

        .category-buttons {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-bottom: 2rem;
        }

        .category-button {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            background-color: #f0f0f0;
            color: var(--text-color);
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.3s ease;
        }

        .category-button.active {
            background-color: var(--primary-green);
            color: white;
        }

        .category-button:hover {
            background-color: #e0e0e0;
        }

        .category-button.active:hover {
            background-color: #45a049;
        }

        .sponsor-text {
            text-align: center;
            font-size: 1rem;
            color: #888;
            margin-bottom: 0.5rem;
        }

        .ad-banner {
            width: 100%;
            max-width: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 2rem;
        }

        .result-container {
            max-width: 800px;
            margin: 2rem auto;
            background-color: #f9f9f9;
            border-radius: 10px;
            padding: 1.5rem;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        .search-query {
            font-weight: bold;
            margin-bottom: 1rem;
        }

        .result-explanation {
            margin-bottom: 1.5rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid #e0e0e0;
        }

        .result-products {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 1.5rem;
        }

        .result-product {
            background-color: #ffffff;
            border-radius: 8px;
            padding: 1rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .result-product img {
            width: 100%;
            height: 150px;
            object-fit: cover;
            border-radius: 5px;
            margin-bottom: 0.5rem;
        }

        .result-product-info h3 {
            color: var(--primary-green);
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
        }

        .result-product-info p {
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
        }

        .product-link {
            display: inline-block;
            margin-top: 0.5rem;
            padding: 0.5rem 1rem;
            background-color: var(--secondary-orange);
            color: white;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            font-size: 0.9rem;
        }

        .product-link:hover {
            background-color: #e68a00;
        }

        .loading {
            display: flex;
            justify-content: center;
            margin: 2rem 0;
        }

        .loading-spinner {
            border: 4px solid #f3f3f3;
            border-top: 4px solid var(--primary-green);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        .result-product.special-offer {
            background-color: #fff9e6;
            border: 2px solid var(--secondary-orange);
        }

        .result-product.special-offer h3 {
            color: var(--secondary-orange);
        }

        .result-product.special-offer .product-link {
            background-color: var(--primary-green);
        }

        .result-product.special-offer .product-link:hover {
            background-color: #45a049;
        }

        .modal {
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
        }

        .modal-content {
            background-color: #fefefe;
            margin: 15% auto;
            padding: 20px;
            border: 1px solid #888;
            width: 80%;
            max-width: 500px;
            border-radius: 10px;
            text-align: center;
        }

        .modal-content h2 {
            color: var(--primary-green);
            margin-bottom: 15px;
        }

        .modal-content p {
            margin-bottom: 20px;
        }

        #emailInput {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            border-radius: 5px;
        }

        #websiteInput {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            border-radius: 5px;
        }

        #nameInput {
            width: 100%;
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            border-radius: 5px;
        }

        #submitEmail {
            background-color: var(--primary-green);
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        #submitEmail:hover {
            background-color: #45a049;
        }

        .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
            cursor: pointer;
        }

        .close:hover,
        .close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }

        .category-results {
            display: none;
        }

        .category-results.active {
            display: block;
        }

        .sponsor-carousel {
            position: relative;
            width: 100%;
            max-width: 500px;
            height: 200px;
            margin: 0 auto 2rem;
            overflow: hidden;
        }

        .sponsor-slide {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
        }

        .sponsor-slide.active {
            opacity: 1;
        }

        .sponsor-slide img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        

        .thank-you-container {
                font-family: 'Arial', sans-serif;
                background-color: #f0f0f0;
                color: #333;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
        }

        .container {
            background-color: white;
            padding: 2rem;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            text-align: center;
            max-width: 600px;
        }
        h1 {
            color: #4CAF50;
        }
        p {
            margin-bottom: 1rem;
        }
        .discount-code {
            font-size: 1.5rem;
            font-weight: bold;
            color: #FF9800;
            margin: 1rem 0;
        }
        .back-link {
            display: inline-block;
            margin-top: 1rem;
            padding: 0.5rem 1rem;
            background-color: #4CAF50;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s ease;
        }
        .back-link:hover {
            background-color: #45a049;
        }

        .partner-section {
            text-align: center;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
        }
        
        .partner-text {
            margin-bottom: 1.6rem;
            font-size: 1rem;
            color: #888;
        }
        
        .partner-button {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            background-color: var(--primary-green);
            color: white;
            border: none;
            border-radius: 5px;
            text-decoration: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }
        
        .partner-button:hover {
            background-color: #45a049;
        }






        .app-footer {
            background-color: #f9f9f9;
            padding: 1.5rem;
            text-align: center;
            border-top: 1px solid #e0e0e0;
            margin-top: 2rem;
          }
          
          .footer-content {
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          
          .footer-disclaimer {
            margin-bottom: 1rem;
            font-size: 0.5rem;
            color: #666;
            max-width: 800px;
            text-align: center;
          }
          
          .footer-links {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
          }
          
          .footer-about-link {
            color: var(--primary-green);
            text-decoration: none;
            font-size: 0.9rem;
            transition: color 0.3s ease;
          }
          
          .footer-about-link:hover {
            color: #45a049;
          }
          
          .footer-copyright {
            font-size: 0.8rem;
            color: #888;
          }