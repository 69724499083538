body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: white;
    color: black;
    line-height: 1.6;
}

.container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
}

.header {
    margin-bottom: 20px;
}

.header h1 {
    font-size: 2.5em;
    margin: 0;
}

.header p {
    font-size: 1.2em;
    color: #333;
}

.content {
    margin-top: 30px;
}

.content p {
    margin-bottom: 20px;
}

.highlight {
    color: green;
    font-weight: bold;
}

footer {
    margin-top: 40px;
    font-size: 0.9em;
    color: #777;
}
